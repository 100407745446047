import { useState, useEffect } from "react";
import "./App.css";

function App() {
  const numbers = Array.from({ length: 15 }, (_, i) => i + 1);
  const [questions, setquestions] = useState(new Array(15));
  const [currentPoint, setcurrentPoint] = useState(1);
  const [showTimer, setshowTimer] = useState(true);
  const [time, setTime] = useState(0);
  const [running, setRunning] = useState(false);

  useEffect(() => {
    let timer;
    if (running) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else if (!running && time !== 0) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [running, time]);

  const resetTimer = () => {
    setTime(0);
    setRunning(false);
  };

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const updateQuestion = (index, newValue) => {
    let tmp = [...questions];
    tmp[index - 1] = newValue;
    setquestions(tmp);
  };

  const playSound = (index) => {
    const utterance = new SpeechSynthesisUtterance(questions[index - 1]);
    window.speechSynthesis.speak(utterance);
  };

  return (
    <div
      className="App"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <h1>Practice English Speaking</h1>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ display: "flex", flexDirection: "column", width: "30%" }}>
          <img
            style={{ width: "100%", objectFit: "cover" }}
            src="/woman.jpg"
          ></img>
          <button
            onClick={() => {
              playSound(currentPoint);
            }}
          >
            play
          </button>
        </div>

        <div style={{ display: "flex", flexDirection: "column", width: "70%" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {numbers.map((number) =>
              currentPoint >= number ? (
                currentPoint == number ? (
                  <div
                    key={number}
                    className="box"
                    style={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: "yellow",
                      alignContent: "center",
                    }}
                  >
                    {number}
                  </div>
                ) : (
                  <div
                    key={number}
                    className="box"
                    style={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: "grey",
                      alignContent: "center",
                    }}
                  >
                    {number}
                  </div>
                )
              ) : (
                <div
                  key={number}
                  className="box"
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: "red",
                    alignContent: "center",
                  }}
                >
                  {number}
                </div>
              )
            )}
          </div>

          {showTimer ? (
            <>
              <h3>
                {String(minutes).padStart(2, "0")}:
                {String(seconds).padStart(2, "0")}
              </h3>
              <button onClick={() => setRunning(!running)}>
                {running ? "Pause" : "Start"}
              </button>
            </>
          ) : null}
          <button onClick={() => setshowTimer(!showTimer)}>
            {showTimer ? "hide" : "show"}
          </button>
          <button
            onClick={() => {
              setcurrentPoint(currentPoint + 1);
              resetTimer();
              setRunning(false);
            }}
            style={{
              marginTop: "auto",
            }}
          >
            next
          </button>
        </div>
      </div>
      <details>
        <summary>펼쳐서 질문을 적으세요</summary>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {numbers.map((number) => (
            <div
              key={number}
              className="box"
              style={{
                width: "500px",
                height: "30px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {number}
              <input
                type="text"
                style={{
                  width: "500px",
                }}
                onChange={(e) => {
                  updateQuestion(number, e.target.value);
                }}
              ></input>
            </div>
          ))}
        </div>
      </details>
    </div>
  );
}

export default App;
